import React from 'react';
import { API } from '../api/APIClient'
import Axios from 'axios'
import {Link} from "react-router-dom";
import _ from 'lodash';
import SimpleAlertDialog, { ModalButton } from '../common/SimpleAlertDialog';



class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search : "",
            results : [],
            modalOpen: false,
            modalTitle: "",
            modalMessage: "",
            modalButtons: [],
            selectedUser : undefined,
            pigeonsInFlight : false
        };
        this.handleChange = this.handleChange.bind(this);
        this.debouncedGetUsers = _.debounce(this.getUsers.bind(this), 400);

    }

    showAlertModal(title, message, buttons) {
        this.setState({
            modalOpen: true,
            modalTitle: title,
            modalMessage: message,
            modalButtons: buttons
        });
    }

    dismissAlertModal() {
        this.setState({
            modalOpen: false
        });
    }
    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            this.debouncedGetUsers();
        });


    }


    componentDidMount()
    {

    }

    clearUser() {
        this.setState({
            selectedUser : undefined,
            userStatus : undefined,
        });
    }

    getUsers()
    {
        if (this.state.search.length > 3 ) {
            const formData = new FormData();
            formData.append('search', this.state.search);
            Axios
                .post(API.user.search, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            results: res.data.data

                        })
                    }
                }).catch(error => {

            });
        } else {
            this.setState({
                results : []
            });
        }

    }

    userClicked(theUser) {

        this.setState({
            selectedUser : theUser
        }, () => {
            this.getUserStatus();
        });

    }


    exportForms(){

        this.showAlertModal(
            "Export Started",
            (<div>Forms export started, an email will be sent to the user once the zip file is ready.</div>),
            [{ label: "OK", onClick: () => this.dismissAlertModal()}]
        );

        const formData = new FormData();

        formData.append('userId', this.state.selectedUser.id);

        // Send data to the server
        Axios
            .post(API.user.exportFormsToZip, formData)
            .then((res) => {
                console.log('woop:exportForms');
            })
            .catch((error) => {
                console.error('Error:', error);

            });

    }

    resetPass() {

        this.setState({
            pigeonsInFlight : true
        });


        const formData = new FormData();

        formData.append('userId', this.state.selectedUser.id);

        // Send data to the server
        Axios
            .post(API.user.sendPasswordReset, formData)
            .then((res) => {
                this.showAlertModal(
                    "Email Sent",
                    (<div>A password reset email has been sent to the user</div>),
                    [{ label: "OK", onClick: () => this.dismissAlertModal()}]
                );
                this.setState({
                    pigeonsInFlight : false
                });
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({
                    pigeonsInFlight : false
                });
            });
    }


    banUser() {
        this.showAlertModal(
            "Ban Confirmation",
            (<div>Do you wish to ban this user? They will be logged out of the app and prevented from logging back in. You can un-ban this user later if needed.</div>),
            [{ label: "Confirm Ban", onClick: () => this.callBanAPI()}, { label: "Cancel", onClick: () => this.dismissAlertModal()}]
        );
    }



    removeCard() {
        this.showAlertModal(
            "Remove Gas Card",
            (<div>Do you wish to remove this user's gas card? They will be prevented from using the app until re-approved.</div>),
            [{ label: "Yes, Remove", onClick: () => this.removeCardAPI()}, { label: "Cancel", onClick: () => this.dismissAlertModal()}]
        );
    }


    removeCardAPI() {

        this.setState({
            pigeonsInFlight : true
        });

        const formData = new FormData();

        formData.append('userId', this.state.selectedUser.id);

        // Send data to the server
        Axios
            .post(API.user.rejectCard, formData)
            .then((res) => {
                this.showAlertModal(
                    "Card Removed",
                    (<div>User's gas card has been removed</div>),
                    [{ label: "OK", onClick: () => this.dismissAlertModal()}]
                );
                this.setState({
                    pigeonsInFlight : false
                }, this.getUserStatus());
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({
                    pigeonsInFlight : false
                });
            });
    }


    approveCard() {

        this.setState({
            pigeonsInFlight : true
        });

        const formData = new FormData();

        formData.append('userId', this.state.selectedUser.id);

        // Send data to the server
        Axios
            .post(API.user.approveCard, formData)
            .then((res) => {
                this.showAlertModal(
                    "Card Aproved",
                    (<div>User's gas card has been approved</div>),
                    [{ label: "OK", onClick: () => this.dismissAlertModal()}]
                );
                this.setState({
                    pigeonsInFlight : false
                }, this.getUserStatus());
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({
                    pigeonsInFlight : false
                });
            });
    }


    getUserStatus() {

        const formData = new FormData();

        formData.append('userId', this.state.selectedUser.id);

        // Send data to the server
        Axios
            .post(API.user.getUserStatus, formData)
            .then((res) => {

                this.setState({
                    userState : res.data.data
                });
            })
            .catch((error) => {
                console.error('Error:', error);

            });
    }



    callBanAPI() {

        this.setState({
            pigeonsInFlight : true
        });

        const formData = new FormData();

        formData.append('id', this.state.selectedUser.id);

        // Send data to the server
        Axios
            .post(API.user.banUser, formData)
            .then((res) => {
                this.showAlertModal(
                    "User Banned",
                    (<div>User has been banned</div>),
                    [{ label: "OK", onClick: () => this.dismissAlertModal()}]
                );
                this.setState({
                    pigeonsInFlight : false
                }, this.getUserStatus());
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({
                    pigeonsInFlight : false
                });
            });
    }


    callUnBanAPI() {

        this.setState({
            pigeonsInFlight : true
        });

        const formData = new FormData();

        formData.append('id', this.state.selectedUser.id);

        // Send data to the server
        Axios
            .post(API.user.unBanUser, formData)
            .then((res) => {
                this.showAlertModal(
                    "User Banned",
                    (<div>User has been un-banned</div>),
                    [{ label: "OK", onClick: () => this.dismissAlertModal()}]
                );
                this.setState({
                    pigeonsInFlight : false
                }, this.getUserStatus());
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({
                    pigeonsInFlight : false
                });
            });
    }

    render() {
        return (
            <div className="gas-cards">
                <header className="header">
                    <Link to={"/"} ><div className="menu-icon"  >
                        <i className="fas fa-home"></i>
                    </div></Link>

                    <div className="big-logo"></div>

                    <div className="logout-icon" onClick={this.props.logoutHandler}>
                        {/* Logout icon */}
                        <i className="fas fa-sign-out-alt"></i>
                    </div>
                </header>

                {this.state.selectedUser && this.state.userState ?
                    <div  className="gas-cards-content">

                        <h1>{this.state.selectedUser.givenName} {this.state.selectedUser.familyName}</h1>
                        <h3>{this.state.selectedUser.email}</h3>

                        {this.state.pigeonsInFlight ?
                            <div>Please Wait...</div>

                            :
                            <div>
                                <div className="ga-button " onClick={() => this.resetPass()} >Send Password Reset</div>
                                <div className="ga-button " onClick={() => this.exportForms()} >Export Forms to Zip</div>

                                {this.state.userState.gasCard ?
                                    <div className="gas-card-img-container">
                                        <img
                                        className="gas-card-image"
                                        src={this.state.userState.gasCard.file_path}
                                        alt="Gas Card"
                                    />
                                    <div className="ga-button " onClick={() => this.removeCard()} >Remove Gas Card</div></div>
                                    :
                                    <div className="ga-button " onClick={() => this.approveCard()} >Manually Approve Gas Card</div>

                                }

                                {this.state.userState.suspended === 1 ?
                                <div className="ga-button " onClick={() => this.callUnBanAPI()} >Remove Ban</div>
                                    :
                                <div className="ga-button " onClick={() => this.banUser()} >Ban User</div>

                                }
                                <div>&nbsp;</div>
                                <div className="ga-button " onClick={() => this.clearUser()} >Back</div>

                            </div>
                        }

                        <SimpleAlertDialog
                            open={this.state.modalOpen}
                            title={this.state.modalTitle}
                            message={this.state.modalMessage}
                        >
                            {
                                this.state.modalButtons.map((button, index) => (
                                    <ModalButton
                                        key={index} // Add a key when mapping
                                        label={button.label}
                                        onClick={button.onClick}
                                        className={button.className}
                                    />
                                ))
                            }
                        </SimpleAlertDialog>
                    </div>
                :
                    <div  className="gas-cards-content">
                        <h1>Enter a name or email</h1>
                        <input
                            style={{fontSize: "16px"}} //
                            className="login-field"
                            type="text"
                            name="search"
                            placeholder=""
                            value={this.state.search}
                            onChange={this.handleChange}
                        />

                        <div className="users-list">
                            {this.state.results.map((user) => {

                                return (<div className="user-row" onClick={() => this.userClicked(user)}>
                                    {user.givenName} {user.familyName} - {user.email}
                                </div>);
                            })}
                        </div>


                    </div>
                }


            </div>
        );
    }
}

export default Search;
